<template>
  <div class="Site">
    <main class="Site-content">
      <img alt="Vue logo" src="./assets/logo.png">
      <HelloWorld msg="欢迎来到提霍"/>
    </main>
    <v-footer></v-footer>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import vFooter from './components/footer.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    vFooter
  }
}
</script>

<style>
.Site {
  display: flex;
  min-height: 95vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<!-- 页脚 -->
<template>
  <div>
    <div class="footer">
      <i>Copyright © 2021 Tifa9fan的个人网站 版权所有 · <a href="http://beian.miit.gov.cn/">陕ICP备2021012955号</a></i>
    </div>
  </div>
</template>

<script>

</script>
<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: aliceblue;
  padding-top: 180px;
}
.footer i {
  display: block;
  margin: 0 auto;
  position: absolute;
  color: #999999;
  text-align: center;
  padding-bottom: 24px;
  /* z-index: 2; */
}
</style>
